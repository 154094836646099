<template>
  <div>
    <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>
    <v-card class="card-shadow border-radius-xl" elevation="5">
      <div class="card-header-padding">
        <div class="font-weight-bold text-h4 text-typo mb-0">
          Pagina Report delle Casse
          <v-chip class="ma-2" color="green" outlined>
            Totale Casse:
          </v-chip>
        </div>
      </div>
      <div class="v-card-text">
        <v-card class="ms-4 me-4 mb-4" style="background-color: #E8EAF6">
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="3">
                  <v-select color="pink" v-model="selectedYear" :items="years" label="Anno"></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select color="pink" v-model="selectedMonth" :items="months" label="Mese"></v-select>
                </v-col>
                <v-col cols="3">
                  <label for="">Seleziona Range di date:</label><br>
                  <date-range-picker
                      v-model="perDate"
                      class="ml-5"
                      style="max-width: 200px"
                      :auto-apply="true"
                      :timePicker="false"
                  >
                    <!--                    <template v-slot:input="picker" style="min-width: 350px;">-->
                    <!--                      {{ picker.startDate }} - {{ picker.endDate }}-->
                    <!--                    </template>-->
                  </date-range-picker>
                  <!--                  <v-text-field v-model="perDate.startDate" label="Data di inizio" type="date"></v-text-field>-->
                </v-col>
                <v-col cols="3">
                  <v-btn
                      :disabled="!searchEnabled"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                    font-weight-bold
                    text-uppercase
                    btn-primary
                    bg-gradient-primary
                    py-2
                    px-6
                    me-2
                    text-xs
                  "
                      @click="search"
                  >cerca</v-btn>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <v-row class="d-inline-flex fullWidth" v-for="(key,value, index) in reportData" :key="index">
          <v-col cols="12">
            <card-reports :titolo="value" :numero="key"></card-reports>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card class="mt-4 card-shadow border-radius-xl" elevation="5">
      <v-card-text>
        <v-data-table
            :items="mensili"
            :headers="headers"
            :expanded.sync="expanded"
            item-key="month"
            show-expand
            :items-per-page="12"
            hide-default-footer
            @click:row="clickRow">
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card class="light ">
                <v-card-text>
                  <v-data-table dense :items="item.casse" :headers="casseHeaders" class="elevation-3">
                    <template v-slot:header="{}">
                      <thead class="v-data-table-header">
                      <tr>
                        <th class="text-danger">MEDIA</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'removed_quantity') }}</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'bootle_sell') }}</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'broken_bottle') }}</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'cork_bottle') }}</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'total_price_cost') }}€</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'costo_asporto_bottle') }}€</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'costo_mescita_bottle') }}€</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'chiusura15') }}€</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'chiusura17') }}€</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'percentuale_bibite') }}%</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'percentuale_cibo') }}%</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'percentuale_vino') }}%</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'total_price_reduce') }}%</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'totalea') }}€</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'totaleb') }}€</th>
                        <th class="text-danger">{{ calculateMediaCasse(item.casse, 'incasso') }}€</th>
                      </tr>
                      </thead>
                    </template>
                    <template v-slot:[`item.data`]="{item}">
                      {{ item.data | formatDate }}
                    </template>
                    <template v-slot:[`item.removed_quantity`]="{item}">
                      <a @click="detailBottle(item)">{{ item.removed_quantity }}</a>
                    </template>
                    <template v-slot:[`item.price_total_reduce`]="{item}">
                      {{ item.price_total_reduce }}€
                    </template>
                    <template v-slot:[`item.totalea`]="{item}">
                      {{ item.totalea }}€
                    </template>
                    <template v-slot:[`item.totaleb`]="{item}">
                      {{ item.totaleb }}€
                    </template>
                    <template v-slot:[`item.incasso`]="{item}">
                      {{ item.incasso }}€
                    </template>
                    <template v-slot:[`item.percentuale_cibo`]="{item}">
                      <v-chip color="blue lighten-2" dark x-small>{{ item.percentuale_cibo }} %</v-chip>
                    </template>
                    <template v-slot:[`item.percentuale_bibite`]="{item}">
                      <v-chip color="indigo lighten-2" dark x-small>{{ item.percentuale_bibite }} %</v-chip>
                    </template>
                    <template v-slot:[`item.percentuale_vino`]="{item}">
                      <v-chip color="teal lighten-2" dark x-small>{{ item.percentuale_vino }} %</v-chip>
                    </template>
                    <template v-slot:[`item.total_price_reduce`]="{item}">
                      <v-chip dark color="teal darken-4" x-small v-if="item.total_price_cost">
                        {{ calculatePercentageTotalCost(item) }} %
                      </v-chip>
                    </template>
                    <template  v-slot:footer="{}">
                      <div class="ml-5 mt-2">
                        T=<span class="text-primary">Bottiglie Rimosse</span> S=<span class="text-primary">Bottiglie Vendute</span> B=<span class="text-primary">Bottiglie Rotte</span> C=<span class="text-primary">Bottiglie Tappo</span>
                      </div>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </td>
          </template>
          <template v-slot:[`item.month`]="{item}">
            {{ item.month }}
          </template>
          <template v-slot:[`item.total_cost`]="{item}">
            {{ item.total_cost | toCurrency }}
          </template>
          <template v-slot:[`item.total_incasso_month`]="{item}">
            {{ item.total_incasso_month | toCurrency }}
          </template>
          <template v-slot:[`item.total_chiusura_15`]="{item}">
            {{ item.total_chiusura_15 | toCurrency }}
          </template>
          <template v-slot:[`item.total_chiusura_17`]="{item}">
            {{ item.total_chiusura_17 | toCurrency }}
          </template>
          <template v-slot:[`item.totale_a`]="{item}">
            {{ item.totale_a | toCurrency }}
          </template>
          <template v-slot:[`item.totale_b`]="{item}">
            {{ item.totale_b | toCurrency }}
          </template>
          <template v-slot:[`item.avg_incasso_per_day`]="{item}">
            {{ item.avg_incasso_per_day | toCurrency }}
          </template>
          <template v-slot:[`item.actions`]="{}">
            <v-icon class="material-icons-rount">file-tree-outline</v-icon>
            <v-icon size="18" class="material-icons-round">mdi-file-tree-outline</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="detailBottleShow" max-width="800" ref="detailsBottle">
      <v-card>
        <v-card-title class="text-h5">
          Details Bottles Sell
        </v-card-title>
        <v-data-table class="table-striped" dense :headers="priceHeaders" :items="priceData">
          <template v-slot:[`item.data`]="{item}">
            {{ item.data | formatDateHour }}
          </template>
        </v-data-table>
        <v-card-text>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="detailBottleShow = false"
          >Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import reportService from "@/services/report.service";
import CardReports from "@/views/Components/CardReports.vue";
import casseService from "@/services/casse.service";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: "ReportFatturato",
  components: {
    CardReports,
    DateRangePicker
  },
  data() {
    return {
      reportData: [],
      mensili: [],
      expanded: [],
      progress: false,
      mesi: [],
      headers: [
        {text: 'Month', value: 'month'},
        {text: 'TB', value: 'totals_bottle_months'},
        {text: 'Total Cost', value: 'total_cost'},
        {text: 'Total Income', value: 'total_incasso_month'},
        {text: 'Closed at 15', value: 'total_chiusura_15'},
        {text: 'Closed at 17', value: 'total_chiusura_17'},
        {text: 'Total A', value: 'totale_a'},
        {text: 'Total B', value: 'totale_b'},
        {text: 'Average Income Day', value: 'avg_incasso_per_day'},
        {text: '%Venduto', value: 'total_margin_percentage'},
        {text: 'Actions', value: 'actions'}
      ],
      casseHeaders: [
        {text: 'data', value: "data", width: '10%', style: 'font-weight: bold; color: #ff5722;' },
        {sortable: false, text: 'T', value: "removed_quantity", width: '2%', style: 'font-weight: bold; color: #ff5722;' },
        {sortable: false, text: 'S', value: "bottle_sell", width: '2%', style: 'font-weight: bold; color: #ff5722;' },
        {sortable: false, text: 'B', value: "broken_bottle", width: '2%', style: 'font-weight: bold; color: #ff5722;' },
        {sortable: false, text: 'C', value: "cork_bottle", width: '2%', style: 'font-weight: bold; color: #ff5722;' },
        {text: 'Costo', value: "total_price_cost", width: "7%", style: 'font-weight: bold; color: #ff5722;' },
        {text: 'C. Asp', value: "costo_asporto_bottle", width: "7%", style: 'font-weight: bold; color: #ff5722;' },
        {text: 'C. Mes', value: "costo_mescita_bottle", width: "7%", style: 'font-weight: bold; color: #ff5722;' },
        {sortable: false, text: 'C15', value: "chiusura15", width: "3%", style: 'font-weight: bold; color: #ff5722;' },
        {sortable: false, text: 'C17', value: "chiusura17", width: "3%", style: 'font-weight: bold; color: #ff5722;' },
        {sortable: false, text: '% Bib', value: "percentuale_bibite", width: "5%", style: 'font-weight: bold; color: #ff5722;' },
        {sortable: false, text: '% Cibo', value: "percentuale_cibo", width: "5%", style: 'font-weight: bold; color: #ff5722;' },
        {sortable: false, text: '% Vino', value: "percentuale_vino", width: "5%", style: 'font-weight: bold; color: #ff5722;' },
        {text: '%Vend.', value: "total_price_reduce", width: "7%", style: 'font-weight: bold; color: #ff5722;' },
        {text: 'totalea', value: "totalea", style: 'font-weight: bold; color: #ff5722;' },
        {text: 'totaleb', value: "totaleb", style: 'font-weight: bold; color: #ff5722;' },
        {text: 'incasso', value: "incasso", style: 'font-weight: bold; color: #ff5722;' },
      ],
      priceHeaders: [
        {text: "nome", value: "nome"},
        {text: "prezzo_costo", value: "prezzo_costo"},
        {text: "prezzo_reduce", value: "prezzo_reduce"},
        {text: "quantity", value: "quantity"},
        {text: "type", value: "type"},
        {text: "data", value: "data"},
      ],
      priceData: null,
      bistrotsData: null,
      showBistrotsDialog: false, // Stato del dialogo dei bistrots
      selectedBistrots: null, // Dati dei bistrots selezionati per visualizzazione
      detailBottleShow: false,
      // filtri
      selectedYear: null,
      selectedMonth: null,
      perDate: {
        startDate: null,
        endDate: null,
      },
      years: [2022, 2023, 2024], // Modifica l'elenco degli anni se necessario
      months: [
        {text: 'Gennaio', value: 1},
        {text: 'Febbraio', value: 2},
        {text: 'Marzo', value: 3},
        {text: 'Aprile', value: 4},
        {text: 'Maggio', value: 5},
        {text: 'Giugno', value: 6},
        {text: 'Luglio', value: 7},
        {text: 'Agosto', value: 8},
        {text: 'Settembre', value: 9},
        {text: 'Ottobre', value: 10},
        {text: 'Novembre', value: 11},
        {text: 'Dicembre', value: 12},
      ],
    }
  },
  filters: {},
  mounted() {
    // this.initReportTotali()
  },
  computed: {
    exctractMonths() {
      return this.mensili.map((item) => item.month)
    },
    searchEnabled() {
      return (this.selectedYear && this.selectedMonth) || (this.perDate.startDate && this.perDate.endDate)
    }
  },
  methods: {
    clickRow(item, event) {
      if (event.isExpanded) {
        const indexExpanded = this.expanded.findIndex(i => i === item);
        this.expanded.splice(indexExpanded, 1)
      } else {
        this.expanded.push(item);
        //           console.log(item.month)
        //   this.progress = true
        //   const params = {
        //     "month": item.month
        //   }
        //   reportService.getReportMensiliDetails(params).then(resp => {
        //         this.expanded = resp.data
        //         this.progress = false
        //       }
        //   ).catch().finally()
        //   // this.expanded.push(item.month);
        // }
      }
    },
    initReportTotali() {
      this.progress = true
      const params = {
        store: this.$store.state.auth.user.store_id
      }
      reportService.getReportTotali(params).then(resp => {
        this.reportData = resp.data[0]
        this.progress = false
        this.initReportMensili()
      })
    },
    initReportMensili() {
      this.progress = true
      const params = {
        store: this.$store.state.auth.user.store_id
      }
      reportService.getReportMensili(params).then(resp => {
        this.mensili = resp.data
        this.progress = false
      })
    },
    initReportCustomDate(params) {
      console.log(params)
    },
    showBistrots(bistrots) {
      this.selectedBistrots = Object.entries(bistrots).map(([date, data]) => ({
        date,
        ...data,
      }));
      this.showBistrotsDialog = true;
    },
    closeBistrotsDialog() {
      this.selectedBistrots = null;
      this.showBistrotsDialog = false;
    },
    detailBottle(item) {
      const params = {
        store: this.$store.state.auth.user.store_id,
        date: item.data
      }
      casseService.detailCasse(params).then(resp => {
        this.priceData = JSON.parse(resp.data)
        this.detailBottleShow = true
      })
    },
    calculateMediaCasse(casse, nomeCampo) {
      const casseConReduce = casse.filter(item => Object.prototype.hasOwnProperty.call(item, nomeCampo) && parseFloat(item[nomeCampo]) !== 0);
      if (casseConReduce.length === 0) {
        return 0;
      }
      const sommaReduce = casseConReduce.reduce((acc, item) => acc + parseFloat(item[nomeCampo]), 0);
      const media = sommaReduce / casseConReduce.length;
      return media.toFixed(2);
    },
    calculatePercentageTotalCost(item) {
      const incasso_vino = (item.percentuale_vino / 100) * item.incasso
      const percentage = (item.total_price_cost * 100) / incasso_vino
      return percentage.toFixed(2)
    },
    calculatePercentageTotalCostMonth(item) {

      return console.log(item)
    },
    search() {
      if (this.selectedYear && this.selectedMonth) {
        const params = {
          year: this.selectedYear,
          store: this.$store.state.auth.user.store_id,
          month: this.selectedMonth
        }
        this.progress = true
        reportService.getReportMensili(params).then(resp => {
          this.mensili = resp.data
          this.progress = false
        })
      }
      if (this.perDate.startDate && this.perDate.endDate) {
        this.progress = true
        this.mensili = []
        this.reportData = []
        const params = {
          store: this.$store.state.auth.user.store_id,
          start_date: this.perDate.startDate.toISOString(),
          end_date: this.perDate.endDate.toISOString()
        }
        console.log("params" + params)
        console.log("this.perDate.startDate" + this.perDate.startDate)
        console.log("this.perDate.endDate" + this.perDate.endDate)
        reportService.getReportTotali(params).then(resp => {
          this.reportData = resp.data[0]
        }).catch(error => console.log(error)).finally(() => {
          const params = {
            store: this.$store.state.auth.user.store_id,
            start_date: this.perDate.startDate.toISOString().substring(0, 10),
            end_date: this.perDate.endDate.toISOString().substring(0, 10)
          }
          reportService.getReportCustomData(params).then(resp => {
            this.mensili = resp.data
            this.progress = false
          })
        })
      }
    }
  },
  watch: {
    selectedYear(val) {
      if (val) {
        this.perDate = {
          startDate: null,
          endDate: null,
        }
      }
    },
    selectedMonth(val) {
      if (val) {
        this.perDate = {
          startDate: null,
          endDate: null,
        }
      }
    },
    perDate(newValue) {
      if (newValue.startDate && newValue.endDate) {
        this.selectedYear = null
        this.selectedMonth = null
      }
    }
  }
}
</script>

<style scoped>
.v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
.custom-header {
  font-weight: bold;
  color: #ff5722; /* Cambia il colore a tua preferenza */
}
.restricted {
  max-width: 20px;
}
</style>
