<template>
  <v-card class="mb-6 card-shadow border-radius-xl mr-5">
    <v-row class="px-8">
      <v-col>
        <v-avatar
            color="bg-warning lighten-2 border-radius-xl mt-n8"
            class="shadow-warning"
            height="64"
            width="64"
        >
          <v-icon class="material-icons-round text-white" size="28" v-if="titolo==='fatturato_totale'">
            mdi-currency-eur
          </v-icon>
          <v-icon class="material-icons-round text-white" size="28" v-else-if="titolo==='giorni_lavorati'">
            mdi-account-hard-hat-outline
          </v-icon>
          <v-icon class="material-icons-round text-white" size="28" v-else-if="titolo==='bottiglie_scaricate'">
            mdi-glass-wine
          </v-icon>
          <v-icon class="material-icons-round text-white" size="28" v-else>
            mdi-ab-testing
          </v-icon>
        </v-avatar>
      </v-col>
      <v-col class="">
        <p class="text-sm mb-0 text-capitalize mb-0">
          {{ toTitleCase(titolo) }}
        </p>
        <h4 class="text-h4 text-typo font-weight-bolder mb-0" v-if="titolo==='giorni_lavorati'">
          {{ numero }}
        </h4>
        <h4 class="text-h4 text-typo font-weight-bolder mb-0" v-else-if="titolo==='bottiglie_scaricate'">
          {{ numero }}
        </h4>
        <h4 class="text-h4 text-typo font-weight-bolder mb-0" v-else>
          {{ numero | toCurrency }}
        </h4>
      </v-col>
    </v-row>
    <hr class="dark horizontal mt-3 mb-4"/>
  </v-card>
</template>

<script>
export default {
  name: "CardReports",
  props: {
    color: String,
    icon: String,
    titolo: [Number, String],
    numero: Number
  },
  data() {
    return {
      title: "",
      cifra: 0,
    }
  },
  methods: {
    toTitleCase(str) {
    return str
        .split('_') // Divide la stringa in parole separate da underscore
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalizza la prima lettera di ogni parola
        .join(' '); // Unisce le parole con uno spazio
}
  }
}
</script>

<style scoped>

</style>
